import { NavController } from '@ionic/angular';
import { ToasterService } from './toaster.service';
import { AuthService } from './auth.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private toast: ToasterService,
    private nav: NavController
  ) { }

  logout(msg: string) {
    this.auth.logout();
    this.nav.navigateRoot('/login');
    this.toast.error(msg);
  }
  post(body, path): Promise<ApiResponse> {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-api-key': this.auth.getToken(),
    });
    const options = {
      headers: header,
    };
    return new Promise((resolve, reject) => {
      this.http
        .post<ApiResponse>(environment.apiUrl + path, JSON.stringify(body), options)
        .subscribe(
          (res) => {
            if (
              res.statusCode === 400 &&
              (res.message === 'Unauthorize access.' ||
                res.message === 'Your session has expired. Please login again!')
            ) {
              this.logout(res.message);
            } else {
              resolve(res);
            }
          },
          (err) => {
            console.log(err);
            this.toast.error('Error occurred');
            reject(err);
          }
        );
    });
  }


  postMultipart(body, path): Promise<ApiResponse> {
    const header = new HttpHeaders({
      'x-api-key': this.auth.getToken(),
    });
    const options = {
      headers: header,
    };

    const formData: FormData = new FormData();
    Object.keys(body).forEach(x => {
      if (typeof body[x] === 'object') {
        // Convert complex objects to a JSON string
        if (Array.isArray(body[x])) {
          body[x].forEach((item, index) => {
            if (item.image && item.image instanceof File) {
              formData.append(`image${index}`, item.image, item.image.name);
            }
          });
        }

        formData.append(x, JSON.stringify(body[x]));
      }
      else {
        formData.append(x, body[x]);
      }
    });

    return new Promise((resolve, reject) => {


      this.http
        .post<ApiResponse>(environment.apiUrl + path, formData, options)
        .subscribe(
          (res) => {
            if (
              res.statusCode === 400 &&
              (res.message === 'Unauthorize access.' ||
                res.message === 'Your session has expired. Please login again!')
            ) {
              this.logout(res.message);
            } else {
              resolve(res);
            }
          },
          (err) => {
            console.log(err);
            this.toast.error('Error occurred');
            reject(err);
          }
        );
    });
  }

  get(path): Promise<ApiResponse> {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-api-key': this.auth.getToken(),
    });
    const options = {
      headers: header,
    };

    return new Promise((resolve, reject) => {
      this.http.get<ApiResponse>(environment.apiUrl + path, options).subscribe(
        (res) => {
          if (
            res.statusCode === 400 &&
            (res.message === 'Unauthorize access.' ||
              res.message === 'Your session has expired. Please login again!')
          ) {
            this.logout(res.message);
          } else {
            resolve(res);
          }
        },
        (err) => {
          console.log(err);
          this.toast.error('Some error occurred. Please try again later!');
          reject();
        }
      );
    });
  }
}

export interface ApiResponse {
  statusCode: number;
  result: any;
  message?: string;
}
